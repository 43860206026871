<template>
  <div
    class="sidebar-panel--from-right js-sidebar-panel-main"
    :class="{ 'sidebar-panel--is-visible': modalActive }"
    v-on-clickaway="closeNow"
  >
    <div class="sidebar-panel__container">
      <header class="sidebar-panel__header border-bottom">
        <h5>{{ modalTitle }}</h5>
        <span class="sidebar-panel__close js-cd-close" @click="closeModal"
          >Close</span
        >
      </header>
      <div class="sidebar-panel__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  data() {
    return {
      component: "",
      hasFocus: false,
    };
  },
  computed: {
    ...mapGetters(["modalActive", "modalTitle", "modalSize", "modalData"]),
  },
  methods: {
    ...mapActions(["openModal", "closeModal"]),
    closeNow() {
      if (this.modalActive) {
        this.closeModal();
      }
    },
  },
};
</script>
