<template>
  <div class="col">
    <div
      class="bg-white rounded border-3 p-3 cursor-pointer mb-4 hover-bg-blue-100 outline-none"
      :class="{'bg-blue-100 border-blue' : this.activePlan === plan.gateway_id}"
      tabindex="0"
      @click="updateActivePlan"
      @keydown.space="updateActivePlan"
    >
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h5 class="text-uppercase text-base mb-0">
          {{ plan.name }}
        </h5>
        <div v-if="this.activePlan === plan.gateway_id">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            class="w-6 h-6"
          ><g
            fill="none"
            fill-rule="nonzero"
          ><path
            fill="#20b5ff"
            d="M31.144 171.84A99.502 99.502 0 1 1 171.841 31.145c37.708 39.043 37.17 101.103-1.212 139.485-38.382 38.381-100.442 38.92-139.485 1.212z"
          /><path
            fill="#fff"
            d="M66.667 89.453l22.885 22.985 42.786-42.786 13.931 14.129-56.717 56.518-36.816-36.816 13.931-14.13z"
          /></g></svg>
        </div>
      </div>
      <div class="mb-1 strong text-blue-darker">
        <p
          class="text-muted"
          v-html="plan.text"
        />
      </div>
      <span class="text-xl strong">${{ plan.amount }}</span>
      <span class="mx-1">/</span>
      <span class="text-lg">mo</span>
    </div>
  </div>
</template>

<script>
	export default {
		model: {
			prop: 'activePlan',
			event: 'onUpdatePlan'
		},
		props: {
			plan: {
				required: true
			},
			activePlan: {
				required: true
			}
		},
		methods: {
			updateActivePlan() {
				this.$emit('onUpdatePlan', this.plan.gateway_id)
			}
		}
	}
</script>