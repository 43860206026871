<template>
  <component :is="layout">
    <base-modal>Pricing</base-modal>

    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <!-- <div class="card border-0 shadow-sm mb-4">
          <div class="card-body">
            <p class="mb-0">
              Over the last 3 months, you've averaged {{ count }} orders per month.
            </p>
          </div>
        </div> -->
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header">
            <p class="mb-0">
              Select your Automation plan
            </p>
          </div>
          <div class="card-body">
            <div class="d-flex row flex-row flex-wrap">
              <selectable-plan
                v-for="plan in setup.plans"
                :key="plan.name"
                v-model="form.plan"
                :plan="plan"
              />
            </div>
            <div class="row credit-card-inputs">
              <div class="form-group col-12 required">
                <label for="cardHolder" class="control-label"
                  >Billing Email</label
                >
                <input
                  id="cardHolder"
                  v-model="form.email"
                  type="text"
                  name="cardHolder"
                  class="form-control"
                />
              </div>
              <div class="form-group col-12 required">
                <label for="cardNumber" class="control-label"
                  >Card Number</label
                >
                <div id="cardNumber" class="form-control stripe-field" />
              </div>
              <div class="form-group col-md-8 required">
                <label for="cardExpiry" class="control-label">Expiry</label>
                <div id="cardExpiry" class="form-control stripe-field" />
              </div>
              <div class="form-group col-md-4 required">
                <label for="cardCvc" class="control-label">CVC</label>
                <div id="cardCvc" class="form-control stripe-field" />
              </div>
            </div>
            <ButtonLoading
              :loading="loading"
              :title="'Save'"
              :class="'btn btn-primary'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header">
            <p class="mb-0">
              Frequently Asked Questions
            </p>
          </div>
          <div class="card-body">
            <div class="faq">
              <p><strong>What currency do you charge in?</strong></p>
              <p>Currently we only charge in <strong>USD</strong>.</p>
            </div>
            <div class="faq">
              <p><strong>Can I cancel at any time?</strong></p>
              <p>
                Yes, of course. And you'll be welcome to use automation.app for
                the remainder of your pre-paid period.
              </p>
            </div>
            <div class="faq">
              <p><strong>Can I change my plan later?</strong></p>
              <p>
                Yep, you can change between Starter, Growth &amp; Premium at any
                time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import BaseModal from "@/components/UI/BaseModal.vue";
import Default from "@/layouts/default.vue";
import SelectablePlan from "@/components/Account//SelectablePlan.vue";
import utilities from "@/helpers/utilities";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  components: {
    BaseModal,
    Default,
    SelectablePlan,
    ButtonLoading,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("/organization/subscription/plans").then((response) => {
      const _plan = to.params.plan;
      next((vm) => {
        vm.setup.plans = response.data;
        if (_plan) {
          vm.form.plan = _plan;
        } else {
          vm.form.plan = response.data[1].gateway_id;
        }
      });
    });
  },
  computed: {
    ...mapGetters(["getOrganization"]),
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      count: 0,
      hasCoupon: false,
      form: {
        email: null,
        plan: null,
        token: null,
      },
      setup: {
        plans: [],
        intent: null,
        public_key: null,
      },
      step: 0,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripeOptions: {
        style: {
          base: {
            fontSize: "16px",
            color: "#506690",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#C6D3E6",
            },
          },
          invalid: {
            color: "#e5424d",
            ":focus": {
              color: "#506690",
            },
          },
        },
      },
    };
  },
  mounted: function() {
    utilities.includeScript(
      "js.stripe.com/v3/",
      function() {
        window.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

        const elements = window.stripe.elements();

        this.cardNumber = elements.create("cardNumber", this.stripeOptions);
        this.cardNumber.mount("#cardNumber");

        this.cardExpiry = elements.create("cardExpiry", this.stripeOptions);
        this.cardExpiry.mount("#cardExpiry");

        this.cardCvc = elements.create("cardCvc", this.stripeOptions);
        this.cardCvc.mount("#cardCvc");
      }.bind(this)
    );
  },
  created() {
    jsonAPI
      .post("/organization/subscription/intent")
      .then((response) => {
        this.setup.intent = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    submitForm() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;
      self.loading = true;
      window.stripe
        .handleCardSetup(this.setup.intent.client_secret, this.cardNumber, {
          payment_method_data: {
            billing_details: {
              name: self.getOrganization.title,
              email: self.form.email,
            },
          },
        })
        .then(function(result) {
          if (result.error) {
            console.error(result.error);
            window.Bus.$emit("flash-message", {
              text: "card error",
              type: "error",
            });
            self.loading = false;
          } else {
            self.form.token = result.setupIntent.payment_method;
            jsonAPI
              .post("/organization/subscription/store", self.form)
              .then(() => {
                self.$router.push({ name: "organization.subscription" });
              })
              .catch(() => {
                window.Bus.$emit("flash-message", {
                  text: "Could not store subscription",
                  type: "error",
                });
                self.loading = false;
              });
          }
        });
    },
  },
};
</script>
